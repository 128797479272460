import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-26365c4bec/0/cache/next-npm-15.0.4-686f370371-2dc86dcb31.zip/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-26365c4bec/0/cache/next-npm-15.0.4-686f370371-2dc86dcb31.zip/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-26365c4bec/0/cache/next-npm-15.0.4-686f370371-2dc86dcb31.zip/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-26365c4bec/0/cache/next-npm-15.0.4-686f370371-2dc86dcb31.zip/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-26365c4bec/0/cache/next-npm-15.0.4-686f370371-2dc86dcb31.zip/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-26365c4bec/0/cache/next-npm-15.0.4-686f370371-2dc86dcb31.zip/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-26365c4bec/0/cache/next-npm-15.0.4-686f370371-2dc86dcb31.zip/node_modules/next/dist/lib/metadata/metadata-boundary.js");
